<template>
  <div>清除cookie
    <router-link to="/Live">返回首页</router-link>
  </div>
  
</template>

<script>
import {ApiToken} from "@/utils/ApiToken"

export default {
  name: 'clear',
  data() {
    return {}
  },
  mounted() {
    localStorage.removeItem('channelType');
    this.$store.commit('channel', -1)
    clearCookie(ApiToken.channel)
    function clearCookie(name) {     
      setCookie(name, "", -1); 
    }
    // 设置cookie   
    function setCookie(c_name, value, expireDays) {                   
      var date = new Date();                   
      date.setDate(date.getDate() + expireDays)      
      document.cookie = c_name + "=" + escape(value)
    } 
  }
}
</script>

<style>

</style>